import "./style.scss";

import { IonCard, IonIcon, IonItem, IonLabel, IonText } from "@ionic/react";
import { chevronForward, newspaper } from "ionicons/icons";
import { useHistory } from "react-router-dom";

import { TabRouterPath } from "../../routing/constant/tabRoute";

export function ContractorAgreement() {
  const history = useHistory();

  const openContract = () => {
    history.push(TabRouterPath.PROFILE_CONTRACT);
  };

  return (
    <IonCard className="view-my-agreement-container">
      <IonItem class="ion-margin-bottom">
        <IonIcon icon={newspaper} />
        <IonLabel>
          <h2 style={{ marginLeft: 15 }}>Contractor Agreement</h2>
        </IonLabel>
      </IonItem>
      <IonItem
        class="license-content"
        lines="none"
        onClick={openContract}
        style={{ cursor: "pointer", width: "100%" }}
      >
        <IonText className="view-my-agreement-text">
          <h6>View My Agreement</h6>
        </IonText>
        <IonIcon slot="end" icon={chevronForward} />
      </IonItem>
    </IonCard>
  );
}
