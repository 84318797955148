import "./style.scss";
import { isDefined } from "@clipboard-health/util-ts";
import { IonAlert } from "@ionic/react";
import { Box, Button } from "@mui/material";
import { useGetSignedAgreements } from "@src/appV2/Agreements/api/useGetSignedAgreements";
import { NoSignedAgreementFileAvailable } from "@src/appV2/Agreements/NoSignedAgreementFileAvailable";
import { AgreementKind } from "@src/appV2/Agreements/types";
import { getNewestSignedAgreementFromResponse } from "@src/appV2/Agreements/utils/getNewestSignedAgreementFromResponse";
import { AppBarHeader, BackButtonLink, PageWithHeader, useToast } from "@src/appV2/lib";
import { APP_BAR_HEIGHT } from "@src/appV2/lib/AppBarHeader/AppBarHeader";
import { toFileNameSafeString } from "@src/appV2/lib/utils/toFileNameSafeString";
import { PdfViewerProvider } from "@src/appV2/PdfViewer/context";
import { PdfViewer } from "@src/appV2/PdfViewer/PdfViewer";
import { PdfViewerLoadingSkeleton } from "@src/appV2/PdfViewer/PdfViewerLoadingSkeleton";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useState } from "react";

import { getAgreement } from "./api";
import { RouterPath } from "../../routing/constant";

export const ContractPage = () => {
  const worker = useDefinedWorker();
  const { showSuccessToast, showErrorToast } = useToast();

  const [showSendEmailAlert, setShowSendEmailAlert] = useState(false);

  const { email, userId, name } = worker;

  const {
    data: signedAgreements,
    isLoading: isLoadingAgreements,
    isError: isErrorAgreements,
    isSuccess: isSuccessAgreements,
  } = useGetSignedAgreements(
    {
      workerId: userId ?? "",
      kind: [AgreementKind.INDEPENDENT_CONTRACTOR],
    },
    { refetchOnWindowFocus: false }
  );

  const emailAgreement = async () => {
    showSuccessToast("The contract has been sent!");
    try {
      await getAgreement(true);
    } catch (error) {
      showErrorToast("There was an error on sending the contract");
    }
  };

  const fileName = `${toFileNameSafeString(name!)}_contractor_agreement.pdf`;
  const pdfUrl = isSuccessAgreements
    ? getNewestSignedAgreementFromResponse(signedAgreements)?.attributes.downloadUrl
    : undefined;

  return (
    <Box sx={{ height: "100vh" }}>
      <PageWithHeader
        appBarHeader={
          <AppBarHeader
            title="Contract Agreement"
            leftCta={<BackButtonLink defaultBackTo={RouterPath.PROFILE} />}
          />
        }
        containerVariant="without-margin"
      >
        <Box
          sx={{
            // since PageWithHeader is not configurable at all, I can't lock container height
            // to be of viewport height and page stretches to length of the pdf itself which makes
            // the whole page to scroll, not just pdf.
            // We also need to subtract inset areas so the button at the bottom is visible and whole page don't scroll
            height: `calc(100vh - ${APP_BAR_HEIGHT} - env(safe-area-inset-bottom) - env(safe-area-inset-top))`,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ flex: 1, overflow: "hidden" }}>
            <PdfViewerProvider fileName={fileName} pdfUrl={pdfUrl}>
              {isLoadingAgreements && <PdfViewerLoadingSkeleton />}
              {isSuccessAgreements && !isDefined(pdfUrl) && <NoSignedAgreementFileAvailable />}
              {isErrorAgreements && <NoSignedAgreementFileAvailable />}
              {isSuccessAgreements && <PdfViewer />}
            </PdfViewerProvider>
          </Box>
          <Button
            variant="contained"
            onClick={() => setShowSendEmailAlert(true)}
            sx={{
              margin: 2,
            }}
          >
            Email Me My Agreement
          </Button>
        </Box>
      </PageWithHeader>
      <IonAlert
        isOpen={showSendEmailAlert}
        onDidDismiss={() => setShowSendEmailAlert(false)}
        header={"Email Your Agreement?"}
        message={`We'll send an email to ${email}`}
        backdropDismiss={true}
        buttons={[
          {
            text: "Never Mind",
            role: "cancel",
            handler: () => setShowSendEmailAlert(false),
          },
          {
            text: "Yes, send",
            handler: emailAgreement,
          },
        ]}
      />
    </Box>
  );
};
