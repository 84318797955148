import { environmentConfig } from "@src/appV2/environment";
import { logApiFailureEvent } from "@src/lib/analytics";
import request from "superagent";

import { getAuthHeader } from "../../superagent";

export const getAgreement = async (sendToEmail = false): Promise<string | undefined> => {
  try {
    const { text } = await request
      .get(`${environmentConfig.REACT_APP_BASE_API_URL}/agentprofile/agreement`)
      .retry(1, (err) => {
        if (!err) {
          return false;
        }
        return true;
      })
      .query({ sendToEmail })
      .set(await getAuthHeader());
    return text;
  } catch (error) {
    logApiFailureEvent(error);
  }
};

export const updateProfile = async (profile): Promise<any> => {
  try {
    const { success } = await request
      .put(`${environmentConfig.REACT_APP_BASE_API_URL}/agentProfile/updateProfile`)
      .set(await getAuthHeader())
      .send({ profile })
      .then(({ body }) => body);
    return success;
  } catch (error) {
    logApiFailureEvent(error);
  }
};

export const requestOTP = async (phone): Promise<any> => {
  try {
    const { body } = await request
      .post(`${environmentConfig.REACT_APP_BASE_API_URL}/agentProfile/requestOTPNew`)
      .set(await getAuthHeader())
      .send({ phone });
    return body;
  } catch (error) {
    logApiFailureEvent(error);
  }
};

export const createDeletionRequest = async (): Promise<{ success: boolean } | undefined> => {
  try {
    const { body } = await request
      .post(`${environmentConfig.REACT_APP_BASE_API_URL}/agentprofile/requestAccountDeletion`)
      .set(await getAuthHeader())
      .send();

    return body;
  } catch (error) {
    logApiFailureEvent(error);
    if (error?.response?.text) {
      return { success: false };
    }
  }
};
