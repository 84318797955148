import "./style.scss";
import { IonPage } from "@ionic/react";
import { TabRouterPath } from "@src/app/routing/constant/tabRoute";
import { AppBarHeader, BackButtonLink, PageWithHeader } from "@src/appV2/lib";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";

import { ConfirmDeletion } from "./confirmDeletion";
import { AccountDeletionSteps } from "./constants";
import { OTPVerification } from "./otpVerification";

export function DeleteData() {
  const [step, setStep] = useState(AccountDeletionSteps.OTP_VERIFICATION);
  const history = useHistory();

  const worker = useDefinedWorker();

  if (worker.requestedAccountDeletion?.requested) {
    return <Redirect to={TabRouterPath.PROFILE} />;
  }

  const onNext = () => {
    if (step === AccountDeletionSteps.CONFIRM_DELETION) {
      history.goBack();
    } else {
      setStep(AccountDeletionSteps.CONFIRM_DELETION);
    }
  };

  return (
    <IonPage>
      <PageWithHeader
        appBarHeader={
          <AppBarHeader
            title="Delete Account"
            leftCta={<BackButtonLink defaultBackTo={TabRouterPath.ACCOUNT} />}
          />
        }
      >
        {step === AccountDeletionSteps.OTP_VERIFICATION && (
          <OTPVerification stepFinished={onNext} showInputBorder={true} />
        )}
        {step === AccountDeletionSteps.CONFIRM_DELETION && (
          <ConfirmDeletion stepFinished={onNext} />
        )}
      </PageWithHeader>
    </IonPage>
  );
}
